<template>
  <ul
    role="list"
    :class="[
      props.responsive
        ? `md:block grid grid-flow-col grid-col-2 grid-rows-[repeat(4,auto)] gap-x-5`
        : ''
    ]"
  >
    <li
      v-for="item in props.items"
      :key="item.name"
      class="mb-3 last:mb-0 md:h-[18px]"
    >
      <NuxtLink
        v-if="!item.external"
        :href="localePath(item.href)"
        :class="linkClass"
      >
        {{ item.name }}
      </NuxtLink>
      <a
        v-else
        :href="item.href"
        rel="nofollow"
        target="_blank"
        :class="linkClass"
      >
        {{ item.name }}
      </a>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  responsive: {
    type: Boolean,
    default: false
  },
  linkClass: {
    type: String,
    default: 'text-xs leading-[18px] text-green-swamp hover:text-orange'
  }
})
</script>
